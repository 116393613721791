import React from 'react';

const Logo = ({ name }) => {
  return (
    <div className="text-4xl font-bold">
      <span className="text-blue-900 rounded-full bg-zinc-200 px-4 py-1">{name?name.charAt(0):''}</span>
    </div>
  );
};

export default Logo;