import React, { useState, useEffect } from 'react';
import '../css/CustomCursor.css';

function CustomCursor() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      const scrollX = window.pageXOffset;
      const scrollY = window.pageYOffset;

      setCursorPosition({
        x: event.clientX + scrollX,
        y: event.clientY + scrollY
      });
    };

    const handleMouseLeave = () => {
      setCursorPosition({ x: -100, y: -100 }); // Set position off-screen
    };

    const handleScroll = () => {
      const scrollX = window.pageXOffset;
      const scrollY = window.pageYOffset;

      setCursorPosition({ x: -100, y: -100 });
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseLeave);
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseLeave);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="cursor-dot" style={{ left: cursorPosition.x, top: cursorPosition.y }}></div>
      <div className="cursor-circle" style={{ left: cursorPosition.x, top: cursorPosition.y }}></div>
    </>
  );
}

export default CustomCursor;