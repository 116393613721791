import React, { createContext, useState } from 'react';
// import axios from "axios";
import initialData from './InitialData';
export const DataStateContext = createContext();

export const DataStateProvider = ({ children }) => {
  const [data, setData] = useState(initialData);
  // const apiUrl = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (apiUrl) {
  //         const response = await axios.get(apiUrl);
  //         if (response.status === 200){
  //           setData(response.data);
  //         }
  //       } else {
  //         console.error('API URL not specified in environment variable.');
  //       }
  //     } catch (error) {
  //       console.error('Error:', error.message);
  //     }
  //   };

  //   fetchData(); // Call the async function
  // }, []); // Run this effect only once, similar to componentDidMount

  return (
    <DataStateContext.Provider value={{ data }}>
      {children}
    </DataStateContext.Provider>
  );
};
